.form-component{
    color:white;
    background: #232323;
}

.formOk-component{
    height: 120vh;
    background: #232323;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20vh;
}

.form-success{
    color: black;
    background: white;
    padding: 50px;
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formOk-heading{
    font-size: 50px;
    font-weight: 700;
    color: var(--primary);
}

.form-header-outer{
    width: 90%;
    margin-right: auto;
    margin-left:auto;
}

.form-header{
    font-size:50px;
    font-weight: 700;
}

.form-values-container{
    background: #fff;
    color:#000000;
    padding: 10px;
}


#root > div > div > div > div.col-lg-6.form-values-container > div > form > div > div > input, textarea{
    min-height: 60px;
    border: 1px solid rgba(0,0,0,0.5);
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-container-form{
    padding: 40px;
    margin: 40px;
    border: 1px solid rgba(0,0,0,.5);
    border-radius: 10px;
}

.submit-btn{
    margin-top: 20px;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 18px !important;
    font-weight: 700 !important;
    cursor: pointer;
    outline: none !important;
    border: none !important;
    background-color: var(--primary) !important;
    color:#242424 !important;
}

.submit-btn:hover{
    background-color: #000000 !important;
    color:#fff !important;
}

.error{
    font-size: small;
    color:red;
}

@media screen and (max-width:992px){
    .form-header{
        font-size:40px;
    }
}

@media screen and (max-width:576px){
    .form-container-form{
        padding: 20px;
        margin: 30px;
    }
}