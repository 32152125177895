.footer {
    display: flex;
    flex-flow: row wrap;
    padding: 100px 30px 20px 30px;
    color:rgba(0,0,0);
    background:#fff;
  }
  
  .footer > * {
    flex:  1 100%;
  }

  .footer-logo-image{
      max-height: 30px;
  }

  
  .footer__addr {
    margin-right: 1.25em;
    margin-bottom: 2em;
  }
  
  
  .footer__addr h2 {
    margin-top: 1.3em;
    font-size: 15px;
    font-weight: 400;
  }
  
  .nav__title {
    font-weight: 400;
    font-size: 15px;
  }
  
  .footer ul {
    list-style: none;
    padding-left: 0;
  }
  
  .footer li {
    line-height: 2em;
  }
  
  .footer a {
    text-decoration: none;
  }
  
  .footer__nav {
    display: flex;
      flex-flow: row wrap;
  }
  
  .footer__nav > * {
    flex: 1 50%;
    margin-right: 1.25em;
  }
  
  .nav__ul a {
    color:rgba(0,0,0,0.6) !important;
  }

  .nav__ul a:hover{
    cursor: pointer;
    color:rgba(0,0,0) !important;
    font-weight: 600;
  }
  
  .nav__ul--extra {
    column-count: 2;
    column-gap: 1.25em;
  }
  
  .legal {
    display: flex;
    color: #999;
  }
    
  .legal__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
  }
  

  .icons-logo{
    max-height:20px;
    margin-right: 10px;
    margin-left: 10px;
    opacity:0.7;
  }

  .icons-logo:hover{
      opacity: 1;
  }

  .marr-2{
    margin-right: 10px;
  }

  @media screen and (max-width:646px) {
    .legal{
      flex-direction: column;
    }

    .legal__links{
      margin-left: 0px;
    }
  }
  
  @media screen and (min-width: 40.375em) {
    .footer__nav > * {
      flex: 1;
    }
    
    .nav__item--extra {
      flex-grow: 2;
    }
    
    .footer__addr {
      flex: 1 0px;
    }
    
    .footer__nav {
      flex: 2 0px;
    }
  }