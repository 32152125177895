.tufan-landing{
    height:100vh;
    background: url("../../../images/Demo/tufan.jpg");
    background-size: cover;
    color:white;
    margin-top: -60px;
}

.tufan-landing-container{
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}