.landingAbout{
    background: #f8f8f8;
}

.landingAbout-container{
    padding-top: 100px;
    padding-bottom: 100px;
}

.landingAbout-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.landingAbout-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}