.landingTeam-header > p{
    font-size: 20px;
    color: rgba(0,0,0,.8);
}

.landingTeam{
    background-color: #f8f8f8;
}

.team-member-description{
    display: none;
}

.active-description{
    display:block;
}

.team-member-name-1{
    font-size: 20px;
}

.team-member-designation-1{
    font-size: 16px;
    color:rgba(0,0,0,.8);
}

.team-member-name{
    font-size: 24px;
    font-weight: 700;
}

.selected-team-member{
    display: flex;
}

/* .team-member-image{
    height: 200px;
} */

@media screen and (min-width:768px){
    .team-member-image{
        height: 150px !important;
    }
}

@media screen and (min-width:1024px){
    .team-member-image{
        height: 200px;
    }
}