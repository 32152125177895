.landingCA2{
    background: var(--primary);
    padding-top:100px;
    padding-bottom: 100px;
}

.landingCA2-container{
    display: flex;
    justify-content: space-around;
}

.landingCA2-button{
    background: #fff !important;
}

.callAction2-button{
    background-color: #fff !important;
    color: black !important;
    border: none !important;
    font-size: 20px !important;
    padding: 8px 20px !important;
    font-weight: 700 !important;
    border-radius: 5px !important;
}

.callAction2-button:hover{
    background-color: black !important;
    color: white !important;
}

@media screen and (max-width:768px){
    .landingCA2-container{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .callAction2-button{
        margin-top: 20px;
    }
}