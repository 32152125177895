.butn{
    padding: 8px 20px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary{
    background-color: var(--primary);
    color: #242424;
}

.btn--outline{
    background-color: transparent;
    color:var(--primary);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium{
    font-weight: 600;
    padding: 8px 29px;
    font-size: 20px;
}

.btn--large{
    font-weight: 600;
    padding: 12px 26px;
    font-size: 24px;
}

.btn--primary:hover{
    background-color: #000000 !important;
    color:#fff !important;
}

.btn--medium:hover, .btn--large:hover{
    background:var(--primary);
    color: #fff;
    transition: all 0.3s ease-out;
}

.margin-5{
    margin-right: 20px;
}
