.landingScreen{
    height:100vh;
    background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ),url("../../../images/LandingPage/background.jpg");
    background-size: cover;
    background-position: center;
    color:white;
    margin-top: -60px;
}

.landingScreen-container{
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.landingScreen-header{
    font-size: 80px;
    font-weight: 800;
}

.landingScreen-subheader{
    font-weight: 500;
    font-size: 22px;
}

@media screen and (max-width:960px){
    .landingScreen-header{
        font-size:60px;
    }

    .landingScreen-subheader{
        font-size: 20px;
    }


}


@media screen and (max-width:576px){
    .landingScreen-header{
        font-size:40px;
    }

    .landingScreen-subheader{
        font-size: 16px;
    }
}