@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Poppins',sans-serif;
}
:root{
  --primary: #80E400;
}


a:hover{
  text-decoration: none !important;
}

.highlighted-text{
  color:var(--primary);
}

.hidden{
  opacity: 0;
  height: 0px;
  transition: height .5s;
}

.display{
  opacity: 1;
  height: 100px;
  transition: height .5s;
  overflow: hidden;
}

.common-subheader{
  font-size: 22px;
  font-weight: 800;
}

.common-subheader2{
  font-size: 22px;
  color: var(--primary);
}

.common-header{
  font-size: 50px;
  font-weight: 700;
}

.common-header-2{
  font-size: 40px;
  font-weight: 700;
}

.subheader2{
  font-size:22px;
  color:rhba(0,0,0,0.8);
}

.common-description{
  font-size: 16px;
  color:rgba(0,0,0,0.6)
}

.small-screen{
  display:none;
}

.spinner-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner{
  color: var(--primary) !important;
  width:100px !important;
  height: 100px !important;
  border-width: 6px !important;
}



@media screen and (max-width: 960px){
  .common-header{
      font-size: 36px;
  }
  .common-subheader{
      font-size: 22px;
  }
}


@media screen and (max-width: 768px){
  .common-header{
      font-size: 32px;
  }
  .common-subheader{
      font-size: 20px;
  }

  .large-screen{
    display:none !important;
  }

  .small-screen{
    display: block;
  }
}