.landingFeatures{
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #f8f8f8;
}

.landingFeatures-content{
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.landingFeatures-text:hover{
    cursor: pointer;
}

.active-sign{
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);	 /*	for IE  */

/* 	for browsers supporting webkit (such as chrome, firefox, safari etc.). */
	-webkit-transform: rotate(90deg);
    display: inline-block;
    transition: transform 0.5s;
}

.inactive-sign{
    transition: transform 0.5s;
}

.landingFeatures-inactive-header{
    color: #CCCCCC;
    font-size: 20px;
    font-weight: 700;
}

.solution-demo-image{
    width: 100%;
}

.solution-feature-points{
    display:flex;
}

.solution-feature-point-image{
    width:50px;
    height: 50px;
    margin-right: 10px;
}