.tufan-feature-1{
    background: #f8f8f8;
}

.tufan-features-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tufan-features-image{
    width: 100%;
}