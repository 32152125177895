.landingSolution{
    padding-top: 100px;
    padding-bottom: 100px;
}

.landingSolution-content{
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.solution-demo-image{
    width: 100%;
}

.solution-feature-points{
    display:flex;
}

.solution-feature-point-image{
    width:50px;
    height: 50px;
    margin-right: 10px;
}