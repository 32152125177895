.pricing{
    padding-top:100px;
    padding-bottom: 100px;
}

.pricing-top{
    font-weight: 600;
}
.pricing-tab{
    background: url("../../../images/LandingPage/logo.png");
    background-repeat: no-repeat;
    background-position: 5% 2%;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 2px 8px 0px, rgba(60, 64, 67, 0.15) 4px 4px 10px 4px;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pricing-tab-2{
    height: 100%;
    background: url("../../../images/LandingPage/logo-white.png");
    background-repeat: no-repeat;
    background-position: 5% 2%;
    background-color: #2c3140;
    color: white;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 2px 8px 0px, rgba(60, 64, 67, 0.15) 4px 4px 10px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pricing-header{
    font-size: 24px;
    font-weight: 600;
}

.pricing-cost{
    font-weight: 800;
    font-size: 40px;
}

.pricing-button{
    width: 80%;
    border: 2px solid blue;
    color: blue;
    font-weight: 600;
}

.pricing-button-2{
    width: 80%;
    border: 2px solid blue;
    background-color: blue;
    color: white;
    font-weight: 600;
}

.pricing-button:hover{
    background-color: blue;
    color: white;
    transition: all 0.3s ease-out;
}

.pricing-button-2:hover{
    background-color: transparent;
    border:2px solid white;
    transition: all 0.3s ease-out;
}

.pricing-line{
    width: 60%;
}

.pricing-list{
    padding-left:0px !important;
    list-style: none;
    font-weight: 600;
}

