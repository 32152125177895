.tufan-demo{
    padding-top: 100px;
    padding-bottom: 100px;
}

.tufan-demo-component{
    background: #232323;
    padding:20px;
}

.tufan-demo-options{
    padding:30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
}

.tufan-demo-button{
    padding: 8px 20px;
    border-radius: 5px;
    width: 100%;
    font-size: 18px !important;
    font-weight: 700 !important;
    cursor: pointer;
    outline: none !important;
    border: none !important;
    background-color: var(--primary) !important;
    color:#242424 !important;
}

.tufan-demo-button:hover{
    background-color: #000000 !important;
    color:#fff !important;
}

.tufan-demo-description{
    color:#fff;
}

.tufan-demo-canvas{
    background-color: #fff;
    min-height: 516px;
}

.tufan-demo-canvas-inner{
    height: 100%;
}

.tufan-demo-results{
    height: 90%;
    padding: 20px;
}

.tufan-demo-image{
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}

.tufan-demo-speed{
    padding:20px;
}

.tufan-demo-speed-card{
    background:#ff8c00;
    padding:10px 20px;
    border-radius: 10px;
    box-shadow: -2px 2px 10px 0px rgba(0,0,0,0.34);
    -webkit-box-shadow: -2px 2px 10px 0px rgba(0,0,0,0.34);
    -moz-box-shadow: -2px 2px 10px 0px rgba(0,0,0,0.34);
}

.tufan-demo-speed-predictions{
    display: flex;
}

.tufan-demo-speed-header{
    font-size: 22px;
    font-weight: 700;
}

.tufan-demo-result-image{
    height: 50px;
    width: 50px;
    margin-right: 20px;
}

.predicted-speed{
    font-size:26px;
    font-weight: 700;
    color: white;
    margin-bottom: 0px;
}

.storm-category{
    color:rgb(0,0,0,0.8);
    font-size: 16px;
    font-weight: 800;
}


.tufan-demo-input-2{
    max-height:278px;
    overflow-y: scroll;
}

.tufan-demo-input-3{
    display:flex;
    flex-direction: column;
}

.tufan-chart{
    background:#fff;
    padding:10px;
}

.tufan-selected-image{
    width: 100%;
    height: 100%;
}

.tufan-selected-image-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tufan-selected-image{
    height: 90%;
    width: 80%;
    border-radius:10px;
    border:none;
}

.tufan-demo-result-container-2{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

@media screen and (max-width:768px){
    .tufan-demo-results{
        height: auto;
    }
}