.jua-demo{
    padding-top: 100px;
    padding-bottom: 100px;
}

.jua-demo-component{
    background: #232323;
    padding:20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.jua-demo-options{
    padding:30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
}

.jua-demo-button{
    padding: 8px 20px;
    border-radius: 5px;
    width: 100%;
    font-size: 18px !important;
    font-weight: 700 !important;
    cursor: pointer;
    outline: none !important;
    border: none !important;
    background-color: var(--primary) !important;
    color:#242424 !important;
}

.jua-demo-button:hover{
    background-color: #000000 !important;
    color:#fff !important;
}

.jua-demo-description{
    color:#fff;
    text-align: left;
}

.jua-demo-canvas{
    background-color: #fff;
    min-height: 516px;
}

.jua-demo-canvas-inner{
    height: 100%;
}

.jua-demo-canvas-results{
    height:80%;
}

.jua-demo-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.jua-demo-result{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.jua-demo-result-inner{
    height:70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

