.software-hero{
    padding-top: 60px;
    padding-bottom: 60px;
}

.software-hero-content{
    padding: 2rem;
}

.software-hero-image{
    max-width: 100%;
}

.software-hero-description{
    font-size: 20px;
}

.software-hero-button{
    font-weight: 600;
    padding: 8px 29px;
    font-size: 20px;
    background-color: var(--primary);
    border: none;
    border-radius: 5px;
}

.software-hero-button:hover{
    background-color: black;
    color: white;
    transition: all 0.3s;
}