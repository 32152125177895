.assets-desc{
    width: 80%;
    font-size: 18px;
}

.assets-header{
    font-size: 24px;
    font-weight: 700;
}

@media screen and (max-width:992px){
    .assets-desc{
        width: 100%;
    }
}